import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import arrow_right_circle from "../../Assets/Icons/arrow_right_circle.svg";
import arrow_right_circle_light from "../../Assets/Icons/arrow_right_circle_light.svg";
import slider_arrow_prev from "../../Assets/Icons/slider_arrow_prev.svg";
import slider_arrow_next from "../../Assets/Icons/slider_arrow_next.svg";
import "./slides.scss";

class SliderComponent extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            nav1: null,
            nav2: null,
            nav3: null
        }
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2,
            nav3: this.slider3
        })
    }

    render() {
        return(
            <React.Fragment>

                <div className="col-lg-4 mt-lg-0 mt-5 col-12 order-lg-0 order-1">

                    {/* Slider left */}
                    <div className="slider-left">
                        <Slider
                        fade={true}
                        arrows={false}
                        asNavFor={this.state.nav1}
                        ref={slider => (this.slider3 = slider)}
                        >
                            {
                                this.props.slider1Slides.map((slide1, i) => {
                                    return <div key={`slide1-${i}`}><img src={slide1} alt="" /></div>
                                })
                            }
                        </Slider>
                    </div>

                </div>

                <div className="col-lg-8 col-12 order-lg-1 order-1">

                    <div className="slides">

                        {/* Slider top */}
                        <div className="slider-top d-md-block d-none">
                            <Slider
                            arrows={false}
                            asNavFor={this.state.nav2}
                            ref={slider => (this.slider1 = slider)}
                            >
                                {
                                    this.props.slider3Slides.map((slide3, i) => {
                                        return <div key={`slide3-${i}`} className="row d-flex align-items-center"><div className="col-lg-4 col-12">
                                            <img src={slide3.image} alt="" />
                                            <h4>{slide3.headline}</h4>
                                            <h5>{slide3.subheadline}</h5>
                                            <a target="_blank" rel="noreferrer" href={slide3.target}>
                                                <img src={arrow_right_circle} alt="" />
                                            </a>
                                        </div>
                                        <div className="col-lg-8 col-12 mt-lg-0 mt-3">
                                            <p>{slide3.description}</p>
                                        </div>
                                    </div>
                                    })
                                }
                            </Slider>
                        </div>

                        {/* Slider bottom */}
                        <div className="slider-bottom">
                            <Slider
                            className={this.props.class}
                            arrows={false}
                            asNavFor={this.state.nav3}
                            ref={slider => (this.slider2 = slider)}
                            slidesToShow={this.props.noOfSlides ? this.props.noOfSlides : 3}
                            responsive={[
                                {
                                    breakpoint: 1640,
                                    settings: {
                                        slidesToShow: 2
                                    }
                                },
                                {
                                    breakpoint: 768,
                                    settings: {
                                        slidesToShow: 1
                                    }
                                }
                            ]}
                            >
                                {
                                    this.props.slider2Slides.map((slide2, i) => {
                                        return <div key={`slide2-${i}`} className="slider-box d-flex align-items-start flex-column justify-content-end">
                                        <div className="slider-box-content">
                                            <img src={slide2.logo} alt="" />
                                            <h2>{slide2.name}</h2>
                                            <span className="d-block">{slide2.shortDescription}</span>
                                            <span className="d-md-none d-block slide2-description">{slide2.description}</span>
                                            <a target="_blank" rel="noreferrer" href={slide2.target}>
                                                <img src={arrow_right_circle_light} alt="" />
                                            </a>
                                        </div>
                                    </div>
                                    })
                                }
                            </Slider>
                        </div>
                        <div className="slider-nav light m-0 mt-4">
                            <button className="me-3" onClick={() => {
                                this.slider2.slickPrev()
                            }}>
                                <img src={slider_arrow_prev} alt="" />
                            </button>
                            <button onClick={() => {
                                this.slider2.slickNext()
                            }}>
                                <img src={slider_arrow_next} alt="" />
                            </button>
                        </div>

                    </div>

                </div>

            </React.Fragment>
        )
    }
}

export default SliderComponent