import React from "react"

import hero_arrow_link from "../../Assets/Icons/hero_arrow_link.svg";
import hero_arrow_target from "../../Assets/Icons/hero_arrow_target.svg";

const LargeButton = (props) => {
    return(
        <React.Fragment>
            <a
            href={props.link}
            style={{
                background: props.background,
                color: props.color
            }}
            className={props.class ? props.class + " large-btn" : "large-btn"}>
                <span>{props.title}</span>
                <img src={
                    props.type === 'link' ?
                    hero_arrow_link
                    :
                    hero_arrow_target
                } alt="" />
            </a>
        </React.Fragment>
    )
}
export default LargeButton