import { useRef, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import Slider from "react-slick";

import slider_arrow_next from "../../Assets/Icons/slider_arrow_next.svg";
import slider_arrow_prev from "../../Assets/Icons/slider_arrow_prev.svg";
import siavash_jalali_v2 from "../../Assets/Images/about/about_team-siavash-jalali_v2.png";
import hardy from "../../Assets/Images/about/about_team_hardy-schloer.png";
import mike_pasic from "../../Assets/Images/about/about_team_mike-pasic.png";
import cryptodata_logo from "../../Assets/Images/about/cryptodata logo.png";
import heinner from "../../Assets/Images/about/heinner.png";
import LSE_Group_logo from "../../Assets/Images/about/LSE_Group_logo.png";
import partners_patentpool from "../../Assets/Images/about/partners_patentpool.png";
import refinitiv_logo from "../../Assets/Images/about/refinitiv_logo.png";
import seb from "../../Assets/Images/about/seb.png";
import thomas from "../../Assets/Images/about/thomas.png";
import video3 from "../../Assets/Video/5.mp4";
import home_about from "../../Assets/Video/poster_about.png";
import Hero from "../../Components/Hero";
import Team from "../../Components/Team";
import { locations } from "../../Constants/locations";

import "./aboutus.scss";
import "react-perfect-scrollbar/dist/css/styles.css";


const Aboutus = () => {

    let partnerSlider = useRef(null);

    const tabRef = (e) => {
        e.preventDefault();
        document.querySelector('.tabs-list li a.active').classList.value = ''
        e.target.classList += 'active'
        document.querySelector('.location-content.active').classList.value = 'location-content'
        document.getElementById(e.target.hash.substring(1)).classList.value += ' active '
    }

    return(
        <div className="about-us">

            <Hero
                headline="About us"
                content={['We promote the fact that data is a valuable resource, and we need to harvest its full potential in everything we do.', <br />, <br />, 'We value diversity and share a common passion for promoting information technology and making a positive impact on today\'s society. We help organizations and individuals extract actionable knowledge from large amounts of information - quickly and easily.']}
                button1Type=""
                button1Text="Mangement"
                button1Link="#team"
                button2Type=""
                poster={home_about}
                button2Text="Partners"
                button2Link="#partners"
                video={video3}
                videoWidth={70}
            />

            <section style={{
                borderTop: '1px solid #A2A2a2'
            }}  className="about-1 section-padding-top section-padding-bottom">
                <div className="container">
                    <div className="row">

                        <div className="col-xl-2 col-12 center-mobile mb-xl-0 mb-4">
                            <p>Contact</p>
                        </div>

                        <div className="col-xl-10 col-12 d-flex align-items-center flex-lg-row flex-column">

                            <ul className="tabs-list mb-lg-0 mb-4 list-unstyled my-0 ps-0 d-inline-block">
                                {
                                    locations.map((location, i) => {
                                        return <li key={`locations-${i}`}>
                                            <a onClick={tabRef} className={location.class} href={`#${location.target}`}>{location.name}</a>
                                        </li>
                                    })
                                }
                            </ul>

                            <div className="tabs-content">
                                <h4 className="company">Prisma Analytics</h4>

                                {
                                    locations.map((location, i) => {
                                        return <div className={`${location.class} location-content`} id={location.target}>
                                            <span className="d-block">{location.address}</span>
                                            <span className="d-block">{location.country}</span>
                                            <span className="d-block">{location.phone}</span>
                                            <span className="d-block">{location.email}</span>
                                        </div>
                                    })
                                }
                            </div>

                        </div>

                    </div>
                </div>
            </section>

            <section id="team" className="about-2 bg-gray section-padding-top section-padding-bottom">
                <div className="container">
                    <div className="row">

                        <div className="col-xxl-2 col-12 center-mobile mb-xxl-0 mb-4">
                            <p>Management Team</p>
                        </div>

                        <div className="col-xxl-10 col-12">
                            <div className="row">

                                <div className="col-xl-4 col-md-6 col-12 mb-4">
                                    <Team
                                    name="Dr. Heiner Pollert"
                                    position="CEO"
                                    image={heinner}
                                    linkedin="https://www.linkedin.com/in/dr-heiner-pollert-b168b5b9/"
                                    description={[<strong>Dr. Heiner Pollert is the CEO of Prisma Analytics.</strong>, <br />, 'Pollert is an expert in the fields of intellectual property and innovation management. In 1998 he founded the Patentpool Group in Munich, focused on innovation management for seed-stage and start-up companies. Dr. Pollert also serves as chairman of the "German Institute of Invention" (Deutsches Institut für Erfindungswesen) and is the former founder and CEO of Ravenpack AG.']} />
                                </div>

                                <div className="col-xl-4 col-md-6 col-12 mb-4">
                                    <Team
                                    name="Siavash Jalali"
                                    position="COO"
                                    image={siavash_jalali_v2}
                                    description={[<strong>Siavash Jalali is a business development professional with over 18 years of experience at large MNCs like “Toshiba”, “Ernst and Young” and family business SMEs like “Schmidt Kranz Holding”.</strong>, <br />, 'At EY, as a Business Development Director he drove compliance and reporting for Europe, Africa, and the Middle East as a business development leader. Until March 2021, Jalali was the COO at Advanced Training Technologies, an integrated learning provider based in Germany. Jalali holds a Master\'s in medical computer science from the University of Heidelberg in Germany and an Executive MBA from the Kellogg School of Management in Evanston/USA and WHU – Otto Beisheim School of Management in Vallendar/Germany.']} />
                                </div>

                                <div className="col-xl-4 col-md-6 col-12 mb-4">
                                    <Team
                                    name="Dr. Hardy F. Schloer"
                                    position="CTO & Founder"
                                    image={hardy}
                                    description={[<strong>Dr. Hardy Schloer is the inventor and CTO of Prisma Analytics and is in charge of the technical development.</strong>, <br />, 'He has over 40 years of experience in system design, development, and implementation. Schloer is the founder and owner of Schloer Consulting Group and board member of several global think tanks such as Club of Amsterdam, Forum 21, and several others. Furthermore, he is the founder and former CEO of Ravenpack International.']} />
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section id="partners" className="about-3 section-padding-top section-padding-bottom">
                <div className="container">
                    <div className="row">

                        <div className="col-xxl-2 col-12 center-mobile mb-xxl-0 mb-4">
                            <p>Our Partners</p>
                        </div>

                        <div className="col-xxl-10 col-12">
                            <Slider
                            ref={slider => partnerSlider = slider}
                            className="partner-slider"
                            arrows={false}
                            slidesToShow={2}
                            slidesToScroll={1}
                            responsive={[
                                {
                                    breakpoint: 991,
                                    settings: {
                                        slidesToShow: 1
                                    }
                                }
                            ]}
                            >
                                <div className="partner d-flex align-items-center justify-content-center">
                                    <img src={partners_patentpool} alt="" />
                                </div>
                                <div className="partner d-flex align-items-center justify-content-center">
                                    <img src={refinitiv_logo} alt="" />
                                </div>
                                <div className="partner d-flex align-items-center justify-content-center">
                                    <img src={cryptodata_logo} alt="" />
                                </div>
                            </Slider>
                            <div className="slider-nav mt-4">
                                <button className="me-3" onClick={() => {
                                    partnerSlider.slickPrev()
                                }}>
                                    <img src={slider_arrow_prev} alt="" />
                                </button>
                                <button onClick={() => {
                                    partnerSlider.slickNext()
                                }}>
                                    <img src={slider_arrow_next} alt="" />
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

        </div>
    )
}
 
export default Aboutus