export const locations = [
    {
        target: 'bucharest',
        class: 'active',
        name: 'Bucharest Office',
        address: 'Calea Floreasca 194',
        country: 'Bucharest, Romania',
        phone: '+49 (0) 89 954 282 520',
        email: 'contact@prisma-analytics.com'
    },
    {
        target: 'munich',
        class: '',
        name: 'Munich Headquarters',
        address: 'Tal 34',
        country: 'Munich 80331, Germany',
        phone: '+49 (0) 89 954 282 520',
        email: 'contact@prisma-analytics.com'
    },
    {
        target: 'abudhabi',
        class: '',
        name: 'Abu Dhabi Office',
        address: 'Sheikh Mohammed bin Zayed',
        country: 'Abu Dhabi, UAE',
        phone: '+49 (0) 89 954 282 520',
        email: 'contact@prisma-analytics.com'
    },
]