import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

import slide_left from "../../Assets/HomeSlider/slider_left.png";
import ai_link from "../../Assets/Icons/ai_link.svg";
import ai_passport from "../../Assets/Icons/ai_passport.svg";
import ai_sentiment from "../../Assets/Icons/ai_sentiment.svg";
import apps_slider_learn_more from "../../Assets/Icons/apps_slider_learn_more.svg";
import close_video from "../../Assets/Icons/close_video.svg";
import read_more_arrow_coral from "../../Assets/Icons/read_more_arrow_coral.svg";
import slider_arrow_next from "../../Assets/Icons/slider_arrow_next.svg";
import slider_arrow_prev from "../../Assets/Icons/slider_arrow_prev.svg";
import slider_logo from "../../Assets/Images/slider_logo.png";
import app_slide_1 from "../../Assets/Images/technology/app_slide_1.png";
import app_slide_2 from "../../Assets/Images/technology/app_slide_2.png";
import app_slide_3 from "../../Assets/Images/technology/app_slide_3.png";
import app_slide_4 from "../../Assets/Images/technology/app_slide_4.png";
import edain_vault from "../../Assets/Images/technology/edain-vault.png";
import feature_1 from "../../Assets/Images/technology/feature_1.svg";
import feature_2 from "../../Assets/Images/technology/feature_2.svg";
import feature_3 from "../../Assets/Images/technology/feature_3.svg";
import feature_4 from "../../Assets/Images/technology/feature_4.svg";
import feature_5 from "../../Assets/Images/technology/feature_5.svg";
import feature_6 from "../../Assets/Images/technology/feature_6.svg";
import feature_7 from "../../Assets/Images/technology/feature_7.svg";
import feature_8 from "../../Assets/Images/technology/feature_8.svg";
import feature_8_1 from "../../Assets/Images/technology/feature_8_1.svg";
import feature_8_2 from "../../Assets/Images/technology/feature_8_2.svg";
import feature_8_3 from "../../Assets/Images/technology/feature_8_3.svg";
import section_7_image from "../../Assets/Images/technology/section_7_image.png";
import section_8_image from "../../Assets/Images/technology/section_8_image.png";
import tech_section_2 from "../../Assets/Images/technology/tech_section_2.png";
import tech_section_5 from "../../Assets/Images/technology/tech_section_5.png";
import tech_section_5_1 from "../../Assets/Images/technology/tech_section_5_1.png";
import technology_video_fallback from "../../Assets/Images/technology/technology_video_fallback.png";
import video2 from "../../Assets/Video/2.mp4";
import home_technology from "../../Assets/Video/poster_technology.png";
import Feature from "../../Components/Features";
import Hero from "../../Components/Hero";
import LargeTitle from "../../Components/LargeTitle";
import SliderComponent from "../../Components/Slides";

import "./technology.scss";

const Technology = () => {

    let appSlider = useRef(null);

    const [videoOpen, setVideoOpen] = useState(false);

    return(
        <div className="technology">

            {
                videoOpen ?
                <div className="modal-video">
                    <div className="modal-video-inner">

                        <div className="text-center mb-3">
                            <button
                            onClick={() => setVideoOpen(false)}
                            className="close-video">
                                <img src={close_video} alt="" />
                            </button>
                        </div>

                        <iframe width="560" height="315" src="https://www.youtube.com/embed/h44ctJh_6d4?controls=0&autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
                : null
            }

            <Hero
                headline={['Unique', <br />, <span className="text-blue">Revolutionary</span>, <br />, 'Innovative']}
                content="The methods, architecture, and technological concepts are fully developed by Prisma Analytics and are patented as unique technologies."
                button1Type=""
                button1Text="Check technology"
                button1Link="#check-technology"
                button2Type="link"
                poster={home_technology}
                button2Text="See solutions"
                button2Link="/technology"
                video={video2}
                videoWidth={70}
            />

            <div id="check-technology" className="section technology-1 section-padding-top section-padding-bottom bg-gray bg-blue">
                <div className="container">

                    <div className="row align-items-end">
                        <div className="col-xxxl-4 col-xl-6 col-12 mb-xl-0 mb-5">
                        <LargeTitle
                            theme="dark"
                            headline={['C+8', <br />, 'Technology®']}
                        />
                        </div>
                        <div className="col-xxxl-8 col-xl-6 col-12">
                            <p className="text-light m-0">A data-importation and transformation engine that allows data to fully self-organize through a complex process of data atomization and systemic association with event causality. C+8 can simultaneously power thousands of centralized applications, from high-powered analytics systems to business services. It does so by parsing, associating, and porting every object captured in its smart decentralized databases.</p>
                            <Link to="/technology" className="btn-link d-flex align-items-center mt-3">
                                <span className="me-2">Read document</span>
                                <img src={read_more_arrow_coral} alt="" />
                            </Link>
                        </div>
                    </div>

                    <div className="row mt-5 pt-5">
                        <div className="col-12 text-center">
                            <button
                            onClick={() => setVideoOpen(true)}
                            className="play-video"
                            >
                                <img src={technology_video_fallback} alt="" />
                            </button>
                        </div>
                    </div>

                </div>
            </div>

            <section className="technology-2 section-padding-top section-padding-bottom">
                <div className="container">
                    <div className="row">

                        <div className="col-xxxl-3 col-xl-4 col-12 mb-xl-0 mb-5">
                        <LargeTitle
                            theme="light"
                            headline={['Data', <br />, 'Collector']}
                        />
                        </div>

                        <div className="col-xxxl-3 col-xl-4 col-12 mb-xl-0 mb-5">
                            <p>A dual-module that collects data from multiple sources and classifies it according to the quality of the information.</p>
                        </div>

                        <div className="col-xxxl-6 col-xl-4 col-12">
                            <img style={{
                                filter: 'grayscale(1)'
                            }} src={tech_section_2} alt="" />
                        </div>

                    </div>
                </div>
            </section>

            <section className="technology-3 bg-gray section-padding-top section-padding-bottom">
                <div className="container">

                    <div className="row">
                        <div className="col-xxl-2 col-12 center-mobile">
                            <p>Data Collector <br /> Functions</p>
                        </div>
                        <div className="col-xxl-10 col-12">

                            <div className="row">

                                <div className="col-lg-4 col-sm-6 col-12">
                                    <Feature type="small" title="Public data gathering" content="Any information that can be found publicly on the internet is collected and classified for further processing." icon={feature_1} />
                                </div>

                                <div className="col-lg-4 col-sm-6 col-12">
                                    <Feature type="small" title="Private data gathering" content="It can receive data from private sources that are made available to the data collector module by the owners of the respective sources." icon={feature_2} />
                                </div>

                                <div className="col-lg-4 col-sm-6 col-12">
                                    <Feature type="small" title="Sources classification" content="The module analyzes the sources and orders them in line with the scoring they receive according to information quality." icon={feature_3} />
                                </div>

                                <div className="col-lg-4 col-sm-6 col-12">
                                    <Feature type="small" title="Data cleaning" content="Collected data is cleaned before being sent for initial raw processing in storage." icon={feature_4} />
                                </div>

                                <div className="col-lg-4 col-sm-6 col-12">
                                    <Feature type="small" title="Text extraction" content="The module extracts texts from documents, articles, or any other available source. Currently extracting data from English language sources." icon={feature_5} />
                                </div>

                                <div className="col-lg-4 col-sm-6 col-12 mb-sm-0 mb-4">
                                    <Feature type="small" comingSoon={true} title="Multi Language" content="Coming soon" icon={feature_6} />
                                </div>

                                <div className="col-lg-4 col-sm-6 col-12 mb-sm-0 mb-4">
                                    <Feature type="small" comingSoon={true} title="Image | Video | Sound analysis" content="Coming soon" icon={feature_7} />
                                </div>

                                <div className="col-lg-4 col-sm-6 col-12">
                                    <Feature type="small" comingSoon={true} title="Social Media" content="Coming soon" icon={feature_8} />
                                </div>

                            </div>

                        </div>
                    </div>

                </div>
            </section>

            <section className="technology-4 section-padding-top section-padding-bottom">
                <div className="container">
                    <div className="row align-items-end">

                        <div className="col-xxxl-4 col-12 mb-xxxl-0 mb-5">
                            <LargeTitle
                                theme="light"
                                headline={['Knowledge', <br />, 'Vault']}
                            />
                        </div>

                        <div className="col-xxxl-4 col-lg-6 col-12 d-xxxl-block d-flex align-items-center">
                            <p>All passports and entities collected information are stored in a decentralized database in which the data sets are organized and analyzed for any form of future interpretation. Knowledge Vault stores two types of data: granular data and raw data.</p>
                        </div>

                        <div className="col-xxxl-4 col-lg-6 col-12">
                            <img src={edain_vault} alt="" />
                        </div>

                    </div>
                </div>
            </section>

            <section className="technology-5 bg-gray section-padding-top section-padding-bottom">
                <div className="container">
                    <div className="row align-items-center">

                        <div className="col-xxl-2 col-sm-4 col-12">
                            <img style={{
                                mixBlendMode: 'luminosity'
                            }} src={tech_section_5} alt="" />
                        </div>

                        <div className="col-xxl-4 col-sm-8 col-12">
                            <h3 className="normal mb-4">Granular Data</h3>
                            <p>The stored data as entities and passports that are causality-based connected is divided into pieces, as small as possible, to be more defined and detailed. The advantage of granular data is that it can be aggregated and disaggregated to meet the needs of different situations.</p>
                        </div>

                        <div className="col-xxl-2 col-sm-4 col-12 mt-xxl-0 mt-5">
                            <img style={{
                                mixBlendMode: 'luminosity'
                            }} src={tech_section_5_1} alt="" />
                        </div>

                        <div className="col-xxl-4 col-sm-8 col-12 mt-xxl-0 mt-sm-5">
                            <h3 className="normal mb-4">Raw Data</h3>
                            <p>All the collected information in the form of documents: video, image, sound, or text, are extracted from sources and stored in raw form so that they are always ready to use.</p>
                        </div>
 
                    </div>
                </div>
            </section>

            <section className="technology-6 bg-blue section-padding-top section-padding-bottom">
                <div className="container">

                    <div className="row">
                        <div className="col-xxxl-4 col-lg-6 col-12 mb-lg-0 mb-5">
                            <LargeTitle
                                theme="dark"
                                headline={['Data', <br />, 'Processor']}
                            />
                        </div>
                        <div className="col-xxxl-8 col-lg-6 col-12">
                            <p className="m-0 text-light">An element that incorporates several parsers with different functions that break down any collected text to discover each word's role and form connections.</p>
                            <Link to="https://edain.ai/technology" className="btn-link d-flex align-items-center mt-3">
                                <span className="me-2">Read more about technology</span>
                                <img src={read_more_arrow_coral} alt="" />
                            </Link>
                        </div>
                    </div>

                    <div className="row mt-5 pt-5">
                    <SliderComponent
                    class="boxfull"
                    noOfSlides={2}
                    slider1Slides={[slide_left, slide_left, slide_left]}
                        slider3Slides={[
                            {
                                image: ai_passport,
                                headline: 'AI Passport',
                                subheadline: 'Module',
                                target: '/',
                                description: 'The Artificial Intelligence module discovers each existing entity in the text from the collected words and generates a passport of characteristics. Each time the entity is identified in old or new sources, these features are added.'
                            },
                            {
                                image: ai_sentiment,
                                headline: 'AI Sentiment',
                                subheadline: 'Module',
                                target: '/',
                                description: 'The AI module verifies the existence of the 72 social emotions on the Social Circumplex Module. The sentiments are added to already generated identity passports based on timestamps. This module extracts sentiments at the sentence, paragraph, and text level for each discovered entity.'
                            },
                            {
                                image: ai_link,
                                headline: 'AI Link Connector',
                                subheadline: 'Module',
                                target: '/',
                                description: 'The AI module establishes the links and connections between entities and the data that represents each entity\'s properties.'
                            }
                        ]}
                        slider2Slides={[
                            {
                                logo: ai_passport,
                                name: 'AI Passport',
                                shortDescription: 'Module',
                                target: '/#decision-point'
                            },
                            {
                                logo: ai_sentiment,
                                name: 'AI Sentiment',
                                shortDescription: 'Module',
                                target: '/#decision-point'
                            },
                            {
                                logo: ai_link,
                                name: 'AI Link Connector',
                                shortDescription: 'Module',
                                target: '/#decision-point'
                            }
                        ]} />
                    </div>

                </div>
            </section>

            <section className="technology-7 section-padding-top section-padding-bottom">
                <div className="container">
                    <div className="row">

                        <div className="col-xxl-5 col-12">
                            <div className="row" style={{height: '100%'}}>
                                <div className="col-xm-6 col-12 mb-sm-0 mb-4">
                                    <LargeTitle
                                        theme="light"
                                        headline={['Data', <br />, 'Flux']}
                                    />
                                </div>

                                <div className="col-xm-6 col-12">
                                    <p>We can offer access to any type of data regardless of the structure, as the technology behind its processing is modular and easily scalable.</p>
                                </div>

                                <div className="col-12 d-sm-flex align-items-end justify-content-between mt-xxl-0 mt-5 mb-xxl-0 mb-5">
                                    <div className="info mb-sm-0 mb-4">
                                        <span>more</span>
                                        <h6>130.000</h6>
                                        <span>news per day</span>
                                    </div>
                                    <div className="info mb-sm-0 mb-4">
                                        <span>average</span>
                                        <h6>1GB</h6>
                                        <span>data storage per day</span>
                                    </div>
                                    <div className="info">
                                        <span>average</span>
                                        <h6>60s</h6>
                                        <span>parsing time per news</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xxl-6 offset-xxl-1 col-12 text-center">
                            <img style={{
                                filter: 'grayscale(1)'
                            }} src={section_7_image} alt="" />
                        </div>

                    </div>
                </div>
            </section>

            <section className="technology-8 bg-gray section-padding-top section-padding-bottom">
                <div className="container">
                    <div className="row">

                        <div className="col-xl-2 col-12 center-mobile">
                            <p>API <br /> Data flux</p>
                        </div>

                        <div className="col-xl-10 col-12">
                            <div className="row">

                                <div className="col-lg-4 col-sm-6 col-12">
                                    <Feature type="small" title="Raw Data API" content="Users can extract raw data available in the Knowledge Vault." icon={feature_8_1} />
                                </div>

                                <div className="col-lg-4 col-sm-6 col-12">
                                    <Feature type="small" title="Processed Data API" content="Extraction of processed data with all the identified connections and associations as well as the characteristics of each data set available in the Knowledge Vault." icon={feature_8_2} />
                                </div>

                                <div className="col-lg-4 col-12">
                                    <Feature type="small" title="Knowledge API" content="Extraction of processed and reorganized data in the form of interpretation needs directly from our developed applications. Also, users can develop their own designs or apps and use the available knowledge in any other form that suits their needs." icon={feature_8_3} />
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className="technology-9 section-padding-top section-padding-bottom">
                <div className="container">
                    <div className="row">

                    <div className="col-xl-3 col-12 mb-xl-0 mb-5">
                            <LargeTitle
                                theme="light"
                                headline="Apps"
                            />
                        </div>

                        <div className="col-xl-3 col-sm-6 col-12 mb-sm-0 mb-4">
                            <p>Our applications are designed to leverage artificial intelligence and big data to provide the standard for unbiased, reality-driven decision-making.</p>
                        </div>

                        <div className="col-sm-6 col-12">
                            <img style={{
                                filter: 'grayscale(1)'
                            }} src={section_8_image} alt="" />
                        </div>

                    </div>
                </div>
            </section>

            <section className="technology-10 bg-gray section-padding-top section-padding-bottom">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-2 col-12 mb-lg-0 mb-5 center-mobile">
                            <p>Apps</p>
                        </div>

                        <div className="col-lg-10 col-12">
                            <div className="row">

                                <Slider
                                ref={slider => appSlider = slider}
                                className="apps-slider"
                                slidesToShow={3}
                                arrows={false}
                                slidesToScroll={1}
                                responsive={[
                                    {
                                        breakpoint: 1200,
                                        settings: {
                                            slidesToShow: 2
                                        }
                                    },
                                    {
                                        breakpoint: 540,
                                        settings: {
                                            slidesToShow: 1
                                        }
                                    },
                                ]}
                                >
                                    <div className="app-slider">
                                        <img src={app_slide_1} alt="" />
                                        <div className="app-slide-content">
                                            <div className="app-inner">
                                                <h4>Decision Point</h4>
                                                <p>A company analysis tool that provides insights on the sentiments available in the market.</p>
                                                <a rel="noreferrer" target="_blank" href="https://decisionpoint.prisma-analytics.com/" className="text-blue d-flex align-items-center">
                                                    <span className="me-3">Learn more</span>
                                                    <img src={apps_slider_learn_more} alt="" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="app-slider">
                                        <img src={app_slide_2} alt="" />
                                        <div className="app-slide-content">
                                            <div className="app-inner">
                                                <h4>Decision Point Crypto</h4>
                                                <p>The only tool in the world that offers a complex sentiment analysis based on 72 social emotions.</p>
                                                <a rel="noreferrer" target="_blank" href="https://decisionpointcrypto.prisma-analytics.com/" className="text-blue d-flex align-items-center">
                                                    <span className="me-3">Learn more</span>
                                                    <img src={apps_slider_learn_more} alt="" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="app-slider">
                                        <img src={app_slide_3} alt="" />
                                        <div className="app-slide-content">
                                            <div className="app-inner">
                                                <h4>QSearch</h4>
                                                <p>Explore the information universe to recreate causal connections and associations between data elements to observe market influences and discover new things.</p>
                                                <a rel="noreferrer" target="_blank" href="https://qsearch.prisma-analytics.com/" className="text-blue d-flex align-items-center">
                                                    <span className="me-3">Learn more</span>
                                                    <img src={apps_slider_learn_more} alt="" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="app-slider">
                                        <img src={app_slide_4} alt="" />
                                        <div className="app-slide-content">
                                            <div className="app-inner">
                                                <h4>Social Circumplex</h4>
                                                <p>The Social Circumplex is a cutting-edge tool that goes above and beyond anything that has been achieved so far by bringing the multiple dimensions of social energy present in discourse out into plain view.</p>
                                                <a rel="noreferrer" target="_blank" href="https://socialcircumplex.prisma-analytics.com/" className="text-blue d-flex align-items-center">
                                                    <span className="me-3">Learn more</span>
                                                    <img src={apps_slider_learn_more} alt="" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </Slider>
                                <div className="slider-nav mt-4">
                                <button className="me-3" onClick={() => {
                                    appSlider.slickPrev()
                                }}>
                                    <img src={slider_arrow_prev} alt="" />
                                </button>
                                <button onClick={() => {
                                    appSlider.slickNext()
                                }}>
                                    <img src={slider_arrow_next} alt="" />
                                </button>
                            </div>

                            </div>
                        </div>

                    </div>
                </div>
            </section>

        </div>
    )
}

export default Technology