
import logo from "../../Assets/Icons/logo.svg";
import { locations } from "../../Constants/locations";
import { menu } from "../Header/constants";
import newsletter_button from "../../Assets/Icons/newsletter_button.svg";
import facebook from "../../Assets/Icons/Social/facebook.svg";
import twitter from "../../Assets/Icons/Social/twitter.svg";
import linkedin from "../../Assets/Icons/Social/linkedin.svg";
import reddit from "../../Assets/Icons/Social/reddit.svg";
import discord from "../../Assets/Icons/Social/discord.svg";
import telegram from "../../Assets/Icons/Social/telegram.svg";
import youtube from "../../Assets/Icons/Social/youtube.svg";

import "./footer.scss";
import { Link } from "react-router-dom";

const Footer = () => {
    return(
        <footer>
            <div className="container">
                <div className="row">

                    <div className="col-xxl-4 col-12 section-padding-top section-padding-bottom">
                        <img src={logo} alt="" />
                        <div className="mt-5"></div>
                        <div className="footer-location row">
                            <div className="col-xxl-6 col-12">
                            {
                                locations.map((hq, i) => {
                                    if(hq.name === 'Munich Headquarters') {
                                        return <div key={"footer-location-" + i} className="location">
                                                <span className="name d-block">{hq.name}</span>
                                                <span className="d-block">{hq.address}</span>
                                                <span className="d-block">{hq.country}</span>
                                            </div>
                                    }
                                })
                            }
                            </div>
                            <div className="col-xxl-6 mt-xxl-0 mt-3 col-12">
                                <ul className="secondary-location list-unstyled m-0 p-0">
                                    {
                                        locations.map((hq, i) => {
                                            if(hq.name !== 'Munich Headquarters') {
                                                return <li key={"footer-location-" + i} className="location">
                                                    <Link to="/contact">{hq.name}</Link>
                                                    </li>
                                            }
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                        <ul className="footer-small-menu list-unstyled p-0 mb-0 mt-5">
                            <li className="d-inline-block me-5">
                                <Link to="/privacy-policy">Privacy Notice</Link>
                            </li>
                            <li className="d-inline-block">
                                <Link to="/cookies">Cookies</Link>
                            </li>
                        </ul>
                    </div>

                    <div className="col-xxl-8 col-12 px-0 bg-blue">
                        <ul className="footer-main-menu d-sm-flex align-items-center justify-content-between">
                            {
                                menu.map((link, i) => {
                                    return <li className="d-sm-inline-block" key={"footer-main-menu " + i}><Link to={link.link}>{link.name}</Link></li>
                                })
                            }
                            <li className="d-inline-block"><Link to="/contact">Contact</Link></li>
                        </ul>
                        <div className="sub-footer">
                            <div className="row m-0">

                                <div className="subscribe col-lg-6 col-12 d-flex justify-content-center">
                                    <form action="#">
                                        <h4 className="mb-4">Subscribe for news</h4>
                                        <div className="position-relative">
                                            <input type="email" placeholder="Enter your email" />
                                            <button className="bg-transparent p-0 border-0">
                                                <img src={newsletter_button} alt="" />
                                            </button>
                                        </div>
                                    </form>
                                </div>

                                <div className="col-lg-6 col-12 social-column">
                                    <div className="footer-social">
                                        <div className="footer-social-wrapper">
                                            <h4 className="mb-4">Find us on</h4>
                                            <ul className="list-unstyled m-0 p-0 d-flex align-items-center justify-content-between">
                                                <li><a href="https://www.facebook.com/prisma.analytics.gmbh" target="_blank" rel="noreferrer"><img src={facebook} alt="" /></a></li>
                                                <li><a href="https://twitter.com/AnalyticsPrisma" target="_blank" rel="noreferrer"><img src={twitter} alt="" /></a></li>
                                                <li><a href="https://www.linkedin.com/company/prisma-analytics/" target="_blank" rel="noreferrer"><img src={linkedin} alt="" /></a></li>
                                                <li><a href="https://www.youtube.com/channel/UC0U-5HiCIFVrot-uBLc_ZFw" target="_blank" rel="noreferrer"><img src={youtube} alt="" /></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </footer>
    )
}
export default Footer