
import { useEffect, useRef } from "react";
import LargeButton from "../LargeButton";

import "./hero.scss";

const Hero = (props) => {

      const vidRef=useRef();
      useEffect(() => { vidRef.current.play(); },[]);

    return(
        <div className="hero align-items-sm-center align-items-end justify-content-center d-flex position-relative flex-column">

            <div className="hero-video">
                <video
                    ref={vidRef}
                    preload={'auto'}
                    id="prismavideo"
                    type={'video/mp4'}
                    poster={props.poster}
                    src={props.video}
                    style={{
                        width: props.videoWidth + '%',
                        background: '#fff'
                    }}
                    autoPlay
                    muted
                    playsInline
                    loop
                ></video>
            </div>

            <div className="hero-inner position-relative">

                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-12">
                            <h1 className="headline">{props.headline}</h1>
                            <p>{props.content}</p>
                        </div>
                    </div>
                </div>

            </div>

            <div className="hero-actions">
                <div className="container text-end">
                    <div className="row d-block lh-0">

                        <LargeButton link={props.button1Link} title={props.button1Text} type={props.button1Type} />
                        <LargeButton link={props.button2Link} title={props.button2Text} type={props.button2Type} />

                    </div>
                </div>
            </div>

        </div>
    )
}
export default Hero