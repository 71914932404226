import { Link } from "react-router-dom";

import slide_left from "../../Assets/HomeSlider/slider_left.png";
import our_mission from "../../Assets/Icons/Features/our_mission.svg";
import our_vision from "../../Assets/Icons/Features/our_vision.svg";
import read_more_arrow_coral from "../../Assets/Icons/read_more_arrow_coral.svg";
import slide1_home from "../../Assets/Icons/slide1_home.svg";
import slide2_home from "../../Assets/Icons/slide2_home.svg";
import slide3_home from "../../Assets/Icons/slide3_home.svg";
import social_circumplex_logo from "../../Assets/Icons/social_circumplex_logo.svg";
import slide_home_1 from "../../Assets/Images/home_1_dp.png";
import slide_home_2 from "../../Assets/Images/home_2_dp.png";
import slide_home_3 from "../../Assets/Images/home_3_dp.png";
import home3_easy_to_use from "../../Assets/Images/homepage/home3_easy_to_use.png";
import home_4 from "../../Assets/Images/homepage/home_3.png";
import home_4_icon from "../../Assets/Images/homepage/home_4_icon.png";
import home_4_image from "../../Assets/Images/homepage/home_4_image.png";
import home_3 from "../../Assets/Images/homepage/home_c8tech.png";
import slider_logo from "../../Assets/Images/slider_logo.png";
import video1 from "../../Assets/Video/1.mp4";
import home_poster from "../../Assets/Video/poster_homepage.png";
import Feature from "../../Components/Features";
import Hero from "../../Components/Hero";
import LargeButton from "../../Components/LargeButton";
import LargeTitle from "../../Components/LargeTitle";
import SliderComponent from "../../Components/Slides";
import SectionTitle from "../../Components/Title";


const Homepage = () => {
    return(
        <div className="homepage">

            <Hero
                headline={['We br', <span className="text-blue">AI</span>, 'nify data']}
                content="News, social media, transcripts, academic journals, and other texts, all contain valuable information that can be transformed into real knowledge."
                button1Type="link"
                button1Text="Business"
                button1Link="/business"
                button2Type="link"
                poster={home_poster}
                button2Text="Check technology"
                button2Link="/technology"
                video={video1}
                videoWidth={90}
            />

            <section className="home-1 section-padding-top bg-gray">
                <div className="container">
                    <div className="row">

                        <div className="col-xxl-4 col-12 mb-xxl-0 mb-5">
                            <SectionTitle width="90%" title="We transform data into knowledge that helps people have an educated perspective to make the best decision." />
                        </div>

                        <div className="col-xxxl-3 col-xxl-4 col-12 col-6 offset-xxxl-1">
                            <Feature icon={our_vision} title="Our Vision" content="Data and information are natural resources that cannot be quantified in a finite number as they are produced permanently with exponential growth. Therefore, the power does not belong to the one who holds the information but to the one that can process and interpret the most significant amount of knowledge in the shortest possible time." />
                        </div>

                        <div className="col-xxxl-3 col-xxl-4 col-12 col-6 offset-xxxl-1">
                            <Feature icon={our_mission} title="Our Mission" content="Information is the most important resource in the Universe. Our mission is to collect all the available information and data, transform it into knowledge, and organize it so that we can deliver it to people in a form that will improve their lives." />
                        </div>

                    </div>
                    <div className="row mt-5">
                        <div className="col-12 text-end lh-0">
                            <LargeButton link="/about-us" title="Read More" type="link" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="home-2 bg-blue section-padding-top section-padding-bottom">
                <div className="container">

                    <div className="row mb-5 pb-5 d-flex align-items-center">
                        <div className="col-xxxl-4 col-lg-6 col-12 mb-lg-0 mb-5">
                            <LargeTitle
                                theme="dark"
                                headline={['User', <br />, 'Friendly', <br />, 'Knowledge']}
                            />
                        </div>
                        <div className="col-xxxl-8 col-lg-6 col-12">
                            <p className="text-light m-0">We have developed a user-friendly system based on blockchain technology that provides knowledge for all users without requiring prior technical background or education.</p>
                            <Link to="/technology" className="btn-link d-flex align-items-center mt-3">
                                <span className="me-2">Read more about technology</span>
                                <img src={read_more_arrow_coral} alt="" />
                            </Link>
                        </div>
                    </div>

                    <div className="row">
                        <SliderComponent slider1Slides={[slide_home_1, slide_home_1, slide_home_1]}
                        slider3Slides={[
                            {
                                image: slide1_home,
                                headline: 'Decision Point',
                                subheadline: 'Sentiment analysis tool',
                                target: 'https://decisionpoint.prisma-analytics.com/',
                                description: 'A company analysis tool that provides insights on the sentiments available in the market.'
                            },
                            {
                                image: slide2_home,
                                headline: 'Decision Point Crypto',
                                subheadline: 'Sentiment analysis tool',
                                target: 'https://decisionpointcrypto.prisma-analytics.com/',
                                description: 'The only tool in the world that offers a complex sentiment analysis based on 72 social emotions.'
                            },
                            {
                                image: slide3_home,
                                headline: 'QSearch',
                                subheadline: 'Information Universe',
                                target: 'https://qsearch.prisma-analytics.com/',
                                description: 'Explore the information universe to recreate causal connections and associations between data elements to observe market influences and discover new things.'
                            },
                            {
                                image: social_circumplex_logo,
                                headline: 'Social Circumplex',
                                subheadline: 'Social Evaluation tool',
                                target: 'https://socialcircumplex.prisma-analytics.com/',
                                description: 'The Social Circumplex is a cutting-edge tool that goes above and beyond anything that has been achieved so far by bringing the multiple dimensions of social energy present in discourse out into plain view.'
                            }
                        ]}
                        slider2Slides={[
                            {
                                logo: slide1_home,
                                name: 'Decision Point',
                                shortDescription: 'Sentiment analysis tool',
                                description: 'A company analysis tool that provides insights on the sentiments available in the market.',
                                target: 'https://decisionpoint.prisma-analytics.com/'
                            },
                            {
                                logo: slide2_home,
                                name: 'Decision Point Crypto',
                                shortDescription: 'Sentiment analysis tool',
                                description: 'The only tool in the world that offers a complex sentiment analysis based on 72 social emotions.',
                                target: 'https://decisionpointcrypto.prisma-analytics.com/'
                            },
                            {
                                logo: slide3_home,
                                name: 'QSearch',
                                shortDescription: 'Causality search tool',
                                description: 'Explore the information universe to recreate causal connections and associations between data elements to observe market influences and discover new things.',
                                target: 'https://qsearch.prisma-analytics.com/'
                            },
                            {
                                logo: social_circumplex_logo,
                                name: 'Social Circumplex',
                                shortDescription: 'Social Evaluation tool',
                                description: 'The Social Circumplex is a cutting-edge tool that goes above and beyond anything that has been achieved so far by bringing the multiple dimensions of social energy present in discourse out into plain view.',
                                target: 'https://socialcircumplex.prisma-analytics.com/'
                            }
                        ]} />
                    </div>
                </div>
            </section>

            <section className="home-3 section-padding-top section-padding-bottom">
                <div className="container">

                    <div className="row align-items-end">
                        <div className="col-xxxl-6 col-lg-8 col-12">
                            <div className="simple-headline">
                                <h1>
                                    <span className="text-blue d-block">Easy to Use</span>
                                    Complex technology
                                </h1>
                            </div>
                        </div>
                        <div className="col-lg-2 pe-lg-0 col-12 mt-lg-0 mt-5">
                            <Link to="/technology" className="btn py-3 d-block text-center">See more</Link>
                        </div>
                    </div>

                    <div className="row align-items-end lh-0 mt-xxxl-0 pt-xxxl-0 mt-5 pt-5">
                        <div className="col-lg-3 col-12">
                            <img src={home_3} alt="" />
                        </div>
                        <div className="col-lg-5 col-12 pe-lg-5 mt-lg-0 mt-5 mb-5">
                            <h3 className="normal mb-4">C+8 Technology®</h3>
                            <p>A data-importation and transformation engine that allows data to fully self-organize through a complex process of data atomization and systemic association with event causality. C+8 can simultaneously power thousands of centralized applications, from high-powered analytics systems to business services. It does so by parsing, associating, and porting every object captured in its smart decentralized databases.</p>
                        </div>
                        <div className="col-lg-4 col-12 ps-lg-0">
                            <img style={{
                                filter: 'grayscale(1)'
                            }} src={home3_easy_to_use} alt="" />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-8 col-12 pe-lg-0 text-end">
                            <LargeButton
                                background="#051EE1"
                                color="#fff"
                                link="https://edain.ai/technology"
                                title="Read More"
                                type="link"
                            />
                        </div>
                    </div>

                </div>
            </section>

            <section className="home-4 section-padding-bottom">
                <div className="container">

                    <div className="row align-items-end lh-0">
                        <div className="col-lg-4 col-12 pe-lg-0">
                            <img style={{
                                filter: 'grayscale(1)'
                            }} src={home_4_image} alt="" />
                        </div>
                        <div className="col-lg-5 px-lg-5 mb-5 mt-lg-0 mt-5">
                            <h3 className="normal mb-4">Knowledge Vault</h3>
                            <p>A knowledge management system that stores both raw processed data and analyzed data (actionable knowledge) with the purpose of making it available for the development of ‘knowledge-based apps’. The KV processes the queries interrogated by users and delivers relevant information according to those queries.</p>
                        </div>
                        <div className="col-lg-3 col-12">
                            <img src={home_4} alt="" />
                        </div>
                    </div>

                    <div className="row">
                        <div className="offset-lg-4 col-lg-3 col-12 ps-lg-0">
                            <LargeButton background="#051EE1" color="#fff" link="https://edain.ai/technology" title="Read More" type="link" />
                        </div>
                    </div>

                </div>
            </section>

        </div>
    )
}
export default Homepage