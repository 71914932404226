
import { menu } from "./constants";
import logo from "../../Assets/Icons/logo.svg";
import "./header.scss";
import { Link } from "react-router-dom";
import React, { useState } from "react";

const Header = () => {

    const [active, setActive] = useState(false);

    return(
        <React.Fragment>
            <header>
                <div className="container">
                    <div className="row align-items-center">

                        <div className="col-xl-3 col-10" style={{
                            lineHeight: 0
                        }}>
                            <a href="/">
                                <img style={{
                                    maxWidth: '80%'
                                }} src={logo} alt="" />
                            </a>
                        </div>

                        <div className="col-6 text-center d-xl-block d-none">
                            <ul className="menu list-unstyled m-0 p-0">
                                {
                                    menu.map((link, i) => {
                                        return <li key={i} className={ i === 0 ? "ms-0 d-inline-block" : i === 3 ? "me-0 d-inline-block" : "d-inline-block"}>
                                            <Link to={link.link} title={link.name}>{link.name}</Link>
                                        </li>
                                    })
                                }
                            </ul>
                        </div>

                        <div className="col-3 text-end d-xl-block d-none">
                            <Link to="/contact" className="btn px-5 py-3">Contact</Link>
                        </div>

                        <div className="col-2 d-xl-none d-block text-end">
                            <button
                            onClick={() => setActive(!active)}
                            className={`${active ? 'active' : ''} burger`}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </button>
                        </div>

                    </div>
                </div>
            </header>
            <div className={`${active ? 'active' : ''} responsive-menu d-flex align-items-center justify-content-center flex-column`}>
                <ul className="menu list-unstyled m-0 p-0">
                    {
                        menu.map((link, i) => {
                            return <li
                            onClick={() => setActive(false)}
                            key={i}
                            className={ i === 0 ? "ms-0 d-inline-block" : i === 3 ? "me-0 d-inline-block" : "d-inline-block"}>
                                <Link to={link.link} title={link.name}>{link.name}</Link>
                            </li>
                        })
                    }
                    <li onClick={() => setActive(false)}>
                        <Link to="/contact" title="Contact">Contact</Link>
                    </li>
                </ul>
            </div>
        </React.Fragment>
    )
}
export default Header;