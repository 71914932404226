
import "./features.scss";

const Feature = (props) => {
    return(
        <div className={`${props.type} ${props.comingSoon ? 'comingSoon' : ''} p-4 feature`}>
             <div className="feature-content">
                 <img src={props.icon} alt="" />
                 <h3>{props.title}</h3>
                 <p>{props.content}</p>
             </div>
        </div>
    )
}
export default Feature