import large_title_dark from "../../Assets/Images/large_title_dark.png";
import large_title_light from "../../Assets/Images/large_title_light.png";
import "./largeTitle.scss";

const LargeTitle = (props) => {
    return(
        <div className={`${props.theme} section-large-title light`} style={{
            backgroundImage: `url(${
                props.theme === 'dark' ? large_title_dark : large_title_light
            })`
        }}>
            <h2>{props.headline}</h2>
        </div>
    )
}

export default LargeTitle