export const menu = [
    {
        name: 'Technology',
        link: '/technology'
    },
    {
        name: 'Business',
        link: '/business'
    },
    {
        name: 'About Us',
        link: '/about-us'
    },
]