import "./team.scss";
import plus_round from "../../Assets/Icons/plus_round_icon.svg";
import close_round from "../../Assets/Icons/close_round.svg";
import { useState } from "react";
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import linkedin from "../../Assets/Icons/Social/linkedin.svg";

const Team = (props) => {

    const [status, setStatus] = useState(false);

    const flip = () => {
        setStatus(true);
    }

    const flipBack = () => {
        setStatus(false);
    }

    return(
        <div className="team">
            <div className={`${status ? 'active' : ''} team-inner`}>

                <div className="front lh-0">
                    <img src={props.image} alt="" />
                    <div className="team-info">
                        <h4>{props.name}</h4>

                        <div className="team-footer d-flex align-items-center justify-content-between">
                            <span>{props.position}</span>
                            <button
                            onClick={flip}
                            className="bg-transparent view-team-info border-0 p-0 ms-3">
                                <img src={plus_round} alt="" />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="back">
                    <div className="back-inner text-center">
                        <div className="text-end">
                            <button
                            onClick={flipBack}
                            className="bg-transparent border-0 p-0">
                                <img src={close_round} alt="" />
                            </button>
                        </div>
                        {
                            props.linkedin ?
                            <a className="mb-3" target="_blank" rel="noreferrer" href={props.linkedin}>
                                <img src={linkedin} alt="" />
                            </a>
                            : null
                        }
                        <h4>{props.name}</h4>
                        <span>{props.position}</span>
                        <div className="back-description">
                            <PerfectScrollbar>
                                <p className="m-0">{props.description}</p>
                            </PerfectScrollbar>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Team