import React from "react"
import { useForm } from "react-hook-form";
import hero_arrow_link from "../../Assets/Icons/hero_arrow_link.svg";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

const ContactForm = () => {

    const {register, formState: { errors }, handleSubmit} = useForm();

    const onSubmit = (data) => {

        let error = true;
        if(data.email !== '' && data.modules !== '' && data.name !== '' && data.phone !== '' && data.subject !== '') {
            error = false;
        }

        if(!error) {

            axios.post(process.env.REACT_APP_MAILER, {data}).then((response) => {
                if(response.data.status === 200) {
                    toast.success('Thank you! Your submission has been sent.', {
                        toastId: 'thank_you'
                    })
                }
                else {
                    toast.warn('Something went wrong! Please try again.', {
                        toastId: 'thank_you'
                    })
                }
            })

        }

    }

    return(
        <React.Fragment>

            <ToastContainer position="top-center" hideProgressBar={true}  />

            {errors.name?.type === 'required' && <p className="error m-0 p-2" style={{background: 'var(--bs-danger)', color: '#fff'}}>Name is required</p>}

            {errors.phone?.type === 'required' && <p className="error m-0 p-2" style={{background: 'var(--bs-danger)', color: '#fff'}}>Phone is required</p>}
            {errors.phone?.type === 'pattern' && <p className="error m-0 p-2" style={{background: 'var(--bs-danger)', color: '#fff'}}>{errors.phone.message}</p>}

            {errors.email?.type === 'required' && <p className="error m-0 p-2" style={{background: 'var(--bs-danger)', color: '#fff'}}>Email is required</p>}
            {errors.email?.type === 'pattern' && <p className="error m-0 p-2" style={{background: 'var(--bs-danger)', color: '#fff'}}>{errors.email.message}</p>}

            {errors.modules?.type === 'required' && <p className="error m-0 p-2" style={{background: 'var(--bs-danger)', color: '#fff'}}>You need to choose a module</p>}

            {errors.subject?.type === 'required' && <p className="error m-0 p-2" style={{background: 'var(--bs-danger)', color: '#fff'}}>Subject is required</p>}

            <form autoComplete="none" onSubmit={handleSubmit(onSubmit)}>

                <div className="contact-form py-md-5 px-md-5 py-4 px-md-0 px-3">
                    <div className="row mb-3">
                        <div className="col-md-3 col-12">
                            <label htmlFor="name">Name</label>
                        </div>
                        <div className="col-md-9 col-12">
                            <input
                            {...register('name', {required: true})}
                            autoComplete="none"
                            type="text"
                            name="name"
                            id="name" />
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-md-3 col-12">
                            <label htmlFor="phone">Phone</label>
                        </div>
                        <div className="col-md-9 col-12">
                            <input
                            {...register('phone',
                            {
                                required: true,
                                pattern: {
                                    value: /[0-9]+$/,
                                    message: "Invalid phone number"
                                }
                            }
                            )}
                            autoComplete="none"
                            type="text"
                            name="phone"
                            id="phone" />
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-md-3 col-12">
                            <label htmlFor="email">Email</label>
                        </div>
                        <div className="col-md-9 col-12">
                            <input
                            {...register('email', {required: true, pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "Invalid email address"
                              }})}
                            autoComplete="none"
                            type="email"
                            name="email"
                            id="email" />
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-md-3 col-12">
                            <label htmlFor="company">Company</label>
                        </div>
                        <div className="col-md-9 col-12">
                            <input
                            {...register('company',
                            {
                                required: false
                            }
                            )}
                            autoComplete="none"  type="text" name="company" id="company" />
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-md-3 col-12">
                            <label htmlFor="activity">Activity domain</label>
                        </div>
                        <div className="col-md-9 col-12">
                            <input
                            {...register('activity',
                            {
                                required: false
                            }
                            )}
                            autoComplete="none"  type="text" name="activity" id="activity" />
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-md-3 col-12">
                            <label htmlFor="modules">Modules</label>
                        </div>
                        <div className="col-md-9 col-12">
                            <select
                            {...register('modules', {required: true})}
                            name="modules"
                            id="modules"
                            >
                                <option value="Decision Point">Decision Point</option>
                                <option value="Decision Point Crypto">Decision Point Crypto</option>
                                <option value="QSearch">QSearch</option>
                            </select>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-md-3 col-12">
                            <label htmlFor="subject">Subject</label>
                        </div>
                        <div className="col-md-9 col-12">
                            <input
                            {...register('subject', {required: true})}
                            autoComplete="none"
                            type="text"
                            name="subject"
                            id="subject" />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-3 col-12">
                            <label htmlFor="description">Description</label>
                        </div>
                        <div className="col-md-9 col-12">
                            <textarea
                            {...register('description',
                            {
                                required: false
                            }
                            )}
                            name="description" id="description"></textarea>
                        </div>
                    </div>
                </div>

                <div className="text-end">
                    <button className="large-btn">
                        <span>Send message</span>
                        <img src={hero_arrow_link} alt="" />
                    </button>
                </div>

            </form>
        </React.Fragment>
    )
}

export default ContactForm