import React, { useLayoutEffect } from 'react';
import ReactDOM from 'react-dom/client';
import Homepage from './Pages/Homepage/Homepage';
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation
} from "react-router-dom";

import "./Assets/Sass/app.scss";

import Header from './Components/Header';
import Footer from './Components/Footer';
import Technology from './Pages/Technology/Technology';
import Aboutus from './Pages/AboutUs/AboutUs';
import Business from './Pages/Business/Business';
import Contact from './Pages/Contact/Contact';
import "./Assets/Sass/responsive.scss";
import Privacy from './Pages/Privacy/Privacy';
import Cookies from './Pages/Cookies/Cookies';

const ScrollTop = () => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>

      <main className="app">
        <ScrollTop />
        <Header />

        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/technology" element={<Technology />} />
          <Route path="/about-us" element={<Aboutus />} />
          <Route path="/business" element={<Business />} />
          <Route path="/contact" element={<Contact />} />

          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/cookies" element={<Cookies />} />
        </Routes>

        <Footer />
      </main>

    </BrowserRouter>
  </React.StrictMode>
);