import Hero from "../../Components/Hero"
import video4 from "../../Assets/Video/4.mp4";
import SectionTitle from "../../Components/Title";
import Feature from "../../Components/Features";
import our_vision from "../../Assets/Icons/Features/our_vision.svg";
import our_mission from "../../Assets/Icons/Features/our_mission.svg";
import LargeButton from "../../Components/LargeButton";
import read_more_arrow from "../../Assets/Icons/read_more_arrow.svg";
import processed_data_api from "../../Assets/Icons/business/processed_data_api.svg";
import knowledge_api from "../../Assets/Icons/business/knowledge_api.svg";
import { Link } from "react-router-dom";
import "./business.scss";
import { useState } from "react";

import business_illustration from "../../Assets/Images/business/business_illustration.png";

import ContactForm from "../../Components/ContactForm";

import home_business from "../../Assets/Video/poster_business.png";

const Business = () => {

    const submitContact = (e) => {
        e.preventDefault(); 
    }

    const [inputs, setInputs] = useState({});

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
    }

    return(
        <div className="business">

            <Hero
                headline={['Knowledge', <br />, <span className="text-blue">Plug & Play</span>, <br />]}
                content="We change the course of human evolution as we have removed the barriers associated with unstructured data and the valueless data oceans that exist. This can add immeasurable value to the global economy and exponential advances in human knowledge expansion."
                button1Type=""
                button1Text="Reseller"
                button1Link="#reseller"
                button2Type=""
                poster={home_business}
                button2Text="Corporate"
                button2Link="#corporate"
                video={video4}
                videoWidth={60}
            />

            <section style={{
                borderTop: '1px solid #A2A2A21'
            }} id="reseller" className="business-1 section-padding-top section-padding-bottom">
                <div className="container">
                    <div className="row">

                        <div className="col-xxl-4 col-12 mb-xxl-0 mb-5 pb-sm-0 pb-5">
                            <SectionTitle width="100%" title="Integrate our apps into your business and benefit of our unique analysis tool." />
                        </div>

                        <div className="col-xxxl-3 col-xxl-4 col-12 col-6 offset-xxxl-1">
                            <Feature icon={our_vision} title="Reseller" content="Providing an innovation hub and incubator for creative interdisciplinary teams to come together and develop solutions for sustainability, security, and the environment, by making use of the world’s first quantum brain." />
                        </div>

                        <div className="col-xxxl-3 col-xxl-4 col-12 col-6 offset-xxxl-1">
                            <Feature icon={our_mission} title="Corporate" content="Providing an innovation hub and incubator for creative interdisciplinary teams to come together and develop solutions for sustainability, security, and the environment, by making use of the world’s first quantum brain." />
                        </div>

                    </div>
                </div>
            </section>

            <section className="business-2 lh-0">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-end">

                            <LargeButton link="#" title="Contact Us" type="link" />

                        </div>
                    </div>
                </div>
            </section>

            <section id="corporate" className="business-3 position-relative bg-gray section-padding-top section-padding-bottom">
                <div className="container position-relative">

                    <div className="illustration-absolute">
                        <img src={business_illustration} alt="" />
                    </div>

                    <div className="row">
                        <div className="col-xxl-4 col-lg-6 col-12">

                            <SectionTitle width="100%" title="Access to any type of data regardless of the structure, as the technology behind is modular and easily scalable." />

                        </div>
                    </div>

                    <div className="row mt-5 pt-5">

                        <div className="col-xxl-3 col-lg-4 col-12 offset-xxl-2">
                            <Feature icon={our_mission} title="Raw Data API" content="Users can extract raw data available in the Knowledge Vault." />
                        </div>

                        <div className="col-xxl-3 col-lg-4 col-12">
                            <Feature icon={processed_data_api} title="Processed Data API" content="Extraction of processed data with all the identified connections and associations as well as the characteristics of each data set available in the Knowledge Vault." />
                        </div>

                        <div className="col-xxl-3 col-lg-4 col-12">
                            <Feature icon={knowledge_api} title="Knowledge API" content="Extraction of processed and reorganized data in the form of interpretation needs directly from our developed applications. Also, users can develop their own designs or apps and use the available knowledge in any other form that suits their needs." />
                        </div>

                    </div>

                </div>
            </section>

            <section className="business-4 section-padding-top section-padding-bottom">
                <div className="container">

                    <div className="row">
                        <div className="col-xxl-4 col-lg-6 col-12 mb-lg-0 mb-5 pb-sm-0 pb-5">
                            <SectionTitle width="100%" title="Personalize your needs with one of our modules from our C+8 Technology." />
                        </div>
                        <div className="col-xxl-7 col-lg-6 col-12 offset-xxl-1">
                            <p>Providing an innovation hub and incubator for creative interdisciplinary teams to come together and develop solutions for sustainability, security, and the environment, by making use of the world’s first quantum brain. We view knowledge as the only commodity able to become a sustainable medium of exchange. Our system’s scalability and generalized nature, finally make it achievable. This brings unprecedented scalability and levels of security.</p>
                            <Link to="/technology" className="btn-link d-flex align-items-center mt-3">
                                <span className="me-2">See technology</span>
                                <img src={read_more_arrow} alt="" />
                            </Link>
                        </div>
                    </div>

                    <div className="row mt-5 pt-5" id="contact-form">
                        <div className="col-xxl-8 col-12 offset-xxl-2">

                            <div className="lh-0">
                                <LargeButton link="#contact-form" title="Contact form" type="target" />
                            </div>

                            <ContactForm />

                        </div>
                    </div>

                </div>
            </section>

        </div>
    )
}

export default Business