import Hero from "../../Components/Hero";
import video3 from "../../Assets/Video/3.mp4";
import { locations } from "../../Constants/locations";
import { Link } from "react-router-dom";
import SectionTitle from "../../Components/Title";
import read_more_arrow from "../../Assets/Icons/read_more_arrow.svg";
import LargeButton from "../../Components/LargeButton";
import business_illustration from "../../Assets/Images/business/business_illustration.png";
import ContactForm from "../../Components/ContactForm";

import home_contact from "../../Assets/Video/poster_contact.png";

const Contact = () => {

    const tabRef = (e) => {
        e.preventDefault();
        document.querySelector('.tabs-list li a.active').classList.value = ''
        e.target.classList += 'active'
        document.querySelector('.location-content.active').classList.value = 'location-content'
        document.getElementById(e.target.hash.substring(1)).classList.value += ' active '
    }

    return(
        <div className="contact">

                <Hero
                headline="Contact Us"
                content="We change the course of human evolution as we have removed the barriers associated with unstructured data and the valueless data oceans that exist. This can add immeasurable value to the global economy and exponential advances in human knowledge expansion."
                button1Type=""
                button1Text="Office"
                button1Link="#office"
                button2Type=""
                poster={home_contact}
                button2Text="Contact"
                button2Link="#contact-form"
                video={video3}
                videoWidth={70}
            />

            <section id="office" className="contact-1 bg-gray section-padding-top section-padding-bottom">

                <div className="container position-relative">

                    <div className="illustration-absolute">
                        <img src={business_illustration} alt="" />
                    </div>

                    <div className="row">

                        <div className="col-xl-2 col-12 center-mobile mb-xl-0 mb-4">
                            <p>Contact</p>
                        </div>

                        <div className="col-xl-10 col-12 d-flex align-items-center flex-lg-row flex-column">

                            <ul className="tabs-list mb-lg-0 mb-4 list-unstyled my-0 ps-0 d-inline-block">
                                {
                                    locations.map((location, i) => {
                                        return <li key={`locations-${i}`}>
                                            <a onClick={tabRef} className={location.class} href={`#${location.target}`}>{location.name}</a>
                                        </li>
                                    })
                                }
                            </ul>

                            <div className="tabs-content">
                                <h4 className="company">Prisma Analytics</h4>

                                {
                                    locations.map((location, i) => {
                                        return <div className={`${location.class} location-content`} id={location.target}>
                                            <span className="d-block">{location.address}</span>
                                            <span className="d-block">{location.country}</span>
                                            <span className="d-block">{location.phone}</span>
                                            <span className="d-block">{location.email}</span>
                                        </div>
                                    })
                                }
                            </div>

                        </div>

                    </div>
                </div>
            </section>

            <section className="contact-2 section-padding-top section-padding-bottom">
                <div className="container">

                    <div className="row">
                        <div className="col-xxl-4 col-lg-6 col-12 mb-lg-0 mb-5">
                            <SectionTitle width="100%" title="Personalize your needs with one of our modules from our C+8 Technology." />
                        </div>
                        <div className="col-xxl-7 col-lg-6 col-12 offset-xxl-1">
                            <p>Providing an innovation hub and incubator for creative interdisciplinary teams to come together and develop solutions for sustainability, security, and the environment, by making use of the world’s first quantum brain. We view knowledge as the only commodity able to become a sustainable medium of exchange. Our system’s scalability and generalized nature, finally make it achievable. This brings unprecedented scalability and levels of security.</p>
                            <Link to="/technology" className="btn-link d-flex align-items-center mt-3">
                                <span className="me-2">See technology</span>
                                <img src={read_more_arrow} alt="" />
                            </Link>
                        </div>
                    </div>

                    <div className="row mt-5 pt-5" id="contact-form">
                        <div className="col-xxl-8 col-12 offset-xxl-2">

                            <div className="lh-0">
                                <LargeButton link="#contact-form" title="Contact form" type="target" />
                            </div>

                            <ContactForm />

                        </div>
                    </div>

                </div>
            </section>

        </div>
    )
}

export default Contact