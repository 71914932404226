import section_image from "../../Assets/Images/section_image_transparent.png";
const SectionTitle = (props) => {
    return(
        <div
        className="section-title position-relative"
        style={{
            width: props.width
        }}>
            <h2>{props.title}</h2>
            <img src={section_image} alt="" />
        </div>
    )
}
export default SectionTitle